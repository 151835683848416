/* --- custom-green-btn start --- */
.custom-green-btn{
    background-color: #082a2b !important;
    color: white !important;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-green-btn:hover{
    background-color: rgb(24 126 129) !important;
    color: white !important;
}
/* --- custom-green-btn end --- */


/* --- custom-slider-btn strat --- */
.custom-slider-btn:hover{
    color: #d2af6d;
    border-color: #d2af6d !important;
}
/* --- custom-slider-btn end --- */

/* --- cart-add-btn start --- */
.cart-add-btn{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cart-add-btn.cab-max:hover{
    color: white;
    border-color: #22c55e !important;
    background-color: #22c55e !important;
}

.cart-add-btn.cab-min:hover{
    color: white;
    border-color: #ff431d !important;
    background-color: #ff431d !important;
}
/* --- cart-add-btn end --- */

/* --- cart-remove-btn start --- */
.cart-remove-btn{
    color: #fa826a;
    padding: 3px 6px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cart-remove-btn:hover{
    color: #fa826a;
    background-color: #fa826a4d;
}
/* --- cart-remove-btn end --- */






/* .header-top-social .lab, */
.header-top-conatct svg{
    color: #d2af6d;
}

.custom-nc-Navitem button,
.header-menu-item{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-nc-Navitem button:hover,
.header-menu-item:hover{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000 !important;
}


.main-slider-bx .main-slider-btn{
    margin-top: 30px !important;
}


/* .hts-item{
    width: ;
} */


/* --- logo Animation start --- */
.anim-logo-bx{
    position: absolute;
    left: 0;
    bottom: 71px;
    width: 150px;
    height: 42px;
    z-index: 1;
    display: none;
}

.header-logo .anim-logo-bx{
    display: block;
}

.anim-logo-bx .icon-r-ani {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 100px;
            perspective: 100px;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-animation: filo-logo 8s infinite;
            animation: filo-logo 8s infinite;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.anim-logo-bx .icon-r-ani .icons-f,
.anim-logo-bx .icon-r-ani .icons-b {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.anim-logo-bx .icon-r-ani .icons-f {
    /* background-image: url('./images/logo/logo-icon.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.anim-logo-bx .icon-r-ani .icons-b {
    /* background-image: url('./images/logo/logo-icon.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transform: rotatey(180deg);
        -ms-transform: rotatey(180deg);
            transform: rotatey(180deg);    
    -webkit-transition: all 0.3s ease-in-out;    
    -o-transition: all 0.3s ease-in-out;    
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes filo-logo {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        transform: rotatey(360deg);
    }
}

@keyframes filo-logo {

    0%,
    5% {
        -webkit-transform: rotatey(0deg);
        transform: rotatey(0deg);
    }

    45%,
    50% {
        -webkit-transform: rotatey(180deg);
        transform: rotatey(180deg);
    }

    100% {
        -webkit-transform: rotatey(360deg);
        transform: rotatey(360deg);
    }
}
/* --- logo Animation end --- */

@media screen and (min-width:0px) {
    .sidemenu-logo img,
    .footer-logo img,
    .header-logo img
    {
        width: 150px;
        height: auto;
        max-height: inherit;
        padding: 10px;
    }

    .header-logo{
        position: relative;
    }

    .anim-logo-bx{
        left: 0;
        top: 10px;
        bottom: inherit;
    }

    .footer-logo img{
        width: 170px;
    }
}


/* @media screen and (min-width:1024px) {
    .header-logo img{
        position: absolute;
        width: 150px;
        height: auto;
        max-height: inherit;
        padding: 10px;
        left: 0;
        bottom: 8px;
    }

    .header-logo{
        position: static;
    }

    .header-logo .anim-logo-bx {
        left: 0;
        top: inherit;
        bottom: 71px;
    }

} */


.footer-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 0;
    margin-top: 10px
}

.copyright {
    font-size: 14px
}

.mede-with {
    font-size: 14px;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.mede-with a {
    font-weight: 500;
    opacity: .8
}

.mede-with:hover a,
.mede-with a:hover {
    opacity: 1;
    color: #fa826a;
}

.icons-box-made {
    width: 18px;
    height: 18px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 7px;
    margin: 0 2px;
}

/* .icons-box-made .fa {
    position: absolute
}

.mede-with .fa:nth-child(1) {
    font-size: 12px;
    transition: all .3s;
    opacity: 1
} */

/* .mede-with .fa:nth-child(2) {
    font-size: 12px;
    transition: all .3s;
    opacity: 0;
    transform: scale(0)
} */

/* .mede-with:hover .fa:nth-child(1) {
    opacity: 0
} */

.mede-with:hover svg {
    opacity: 1;
    fill: #fa826a;
    color: #fa826a;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}



@media screen and (max-width: 768px) {
    .footer-bottom {
        -webkit-box-orient:vertical;
        -webkit-box-direction:normal;
            -ms-flex-direction:column;
                flex-direction:column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
    }

    .copyright {
        margin-bottom: 10px
    }
}

@media screen and (max-width: 359px) {
    .header-top-conatct a:last-child{
        display: none;
    }
}


/* --- Product Slider css start --- */
.thumbnail-slider .slick-active .thumbnail-item{
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
    border: 2px solid rgb(226 232 240);
}
.thumbnail-slider .slick-active .thumbnail-item:hover{
    opacity: 1;
    border: 2px solid #d2af6d;
}

.thumbnail-slider .slick-current .thumbnail-item{
    opacity: 1;
    border-color:#d2af6d;
}
/* --- Product Slider css end --- */


/* --- Skeletant Design css start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: rgb(243, 244, 246);
    background-image: url("./images/placeholder-small4.png");
    /* background-image: url("./images/logo/icon-gray.png"),  linear-gradient(90deg, #192436 40%, #111827 50%, #192436 60%); */
    background-position:center;
    background-repeat:repeat;
    background-size:400px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 5s infinite linear;
    pointer-events: none;
    /* box-shadow: 0 0 20px -10px #c4c4c4; */
    border-radius: 16px;
}

.skeletant-design.ld-no-img{
    background-size:0;
    animation: shine-lines 0.3s infinite linear;
}

.skeletant-design.ld-no-radius{
    border-radius: 0;
}

@-webkit-keyframes shine-lines {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }
    
    100%{
        opacity: 1;
    }
}

@keyframes shine-lines {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }

    100%{
        opacity: 1;
    }
}
/* --- Skeletant Design css end --- */


/* --- Silke slider btn css start --- */
.slick-arrow{
    width: 40px;
    height: 40px;
    border: 2px solid #d6d6d6;
    background-color: transparent;
    border-radius: 50%;
    font-size: 24px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    opacity: 0.9;
    margin: 0;
    padding: 5px;
    overflow: hidden;
    color: transparent;
}

.slick-arrow.slick-prev:before, 
.slick-arrow.slick-next:before{
    content: none;
}

.slick-arrow::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 22px 22px;
    transition: all 0.3s ease-in-out;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
    position: absolute;
    transform: translateY(-50%);
    z-index: 5;
}

.slick-arrow.slick-prev {
    left: 15px;
    top: 50%;
}

.slick-arrow.slick-prev::after {
    background-position: center center;
    background-image: url("/src/images/icons/chevron-back-outline.svg");
}

.slick-arrow.slick-next{
    right: 15px;
    top: 50%;

}

.slick-arrow.slick-next::after {
    background-position: center center;
    background-image: url("/src/images/icons/chevron-forward-outline.svg");
}


.slick-arrow:hover{
    border-color: #d2af6d;
    background-color: #d2af6d;
    color: transparent;
}

.nav-warro-white .slick-arrow.slick-next::after,
/* .nav-warro-white .slick-arrow.slick-next:hover::after, */
.slick-arrow.slick-next:hover::after {
    background-image: url("/src/images/icons/chevron-forward-outline-white.svg");
}

.nav-warro-white .slick-arrow.slick-prev::after,
/* .nav-warro-white .slick-arrow.slick-prev:hover::after, */
.slick-arrow.slick-prev:hover::after {
    background-image: url("/src/images/icons/chevron-back-outline-white.svg");
}

.slick-disabled{
    opacity: 0.5;
    cursor: inherit;
}

/* --- Silke slider btn css end --- */

/* --- Lightgallery start --- */

.lg-counter{
    color: white !important;
}

.lg-toolbar .lg-icon{
    color: rgba(255, 255, 255, 0.7) !important;
}

.lg-toolbar .lg-icon:hover{
    color: #ffffff !important;
}

.lg-download.lg-icon{
    display: none !important;
}

.lg-backdrop{
    background-color: #0000004d !important;
    backdrop-filter: blur(5px) !important;
}

.lg-prev,
.lg-next{
    width: 46px !important;
    height: 46px !important;
    color: #d2af6d !important;
    border: 2px solid #d2af6d !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    transition: all 0.3s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover{
    color: white !important;
    background-color: #d2af6d !important;
}

.lg-img-wrap{
    padding: 20px !important;
    padding-top: 0 !important;
}
/* --- Lightgallery end --- */


/* --- Lagal pages css start --- */
.lagle-container{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
}

.lagle-heading{
    color: #d2af6d;
    font-size: 32px;
    margin-bottom: 35px;
    font-weight: 600;
    text-align: center;
}

.legal-content .main-lable {
    margin-top: 15px;
    margin-bottom: -20px;
    font-weight: 500;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 500;
    color: rgb(55, 65, 81);
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-ul li,
.legal-content p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-top: 15px;
    color: rgb(75, 85, 99);
    font-weight: 400;
}

.legal-ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 15px;
    /* list-style-position: inside; */
}

.legal-ul.ul-dick {
    list-style-type: none;
}

.legal-ul li a,
.legal-content p a {
    text-decoration: underline;
}

.legal-ul li a:hover,
.legal-content p a:hover {
    color: #d2af6d;
}
/* --- Lagal pages css end --- */

/* --- Sitemap Page Css --- */
.sitemaplinks-bx {
    padding: 10px 10px 10px 10px;
    position: relative;
    margin-bottom: 40px;
}

.sitemap-heding {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    color: rgb(55, 65, 81);
    display: block;
    padding-bottom: 10px;
}

.sitemap-heding::before,
.sitemap-heding::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    border-radius: 5px;
    background-color: rgb(55, 65, 81);
}

.sitemap-heding::before {
    left: inherit;
    right: 0;
    top: 100%;
    width: calc(100% - 30px);
    background-color: rgb(55, 65, 81);
}

.sitemap-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sitemap-ul li {
    width: 19%;
    display: inline-block;
    margin: 4px 0;
}

.sitemap-ul li .sitemap-link {
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    color: rgb(75, 85, 99);
    font-weight: 400;
}

.sitemap-ul li .sitemap-link svg {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.sitemap-ul li .sitemap-link:hover {
    color: #d2af6d;
    transform: translateX(10px);
}

.sitemap-ul li .sitemap-link:hover svg {
    color: #d2af6d;
}

@media screen and (max-width:992px) {
    .sitemap-ul li {
        width: 24%;
    }
}

@media screen and (max-width:768px) {
    .sitemap-ul li {
        width: 32%;
    }
}

@media screen and (max-width:576px) {
    .sitemap-ul li {
        width: 49%;
    }
}
/* --- Sitemap page css end --- */


/* --- Faqs page css --- */
.faqs-accordion{
    background-color: rgb(244 247 250);
    border-radius: 16px;
    margin-top: 20px;
    padding: 10px 20px;
    box-shadow: 0 0 50px -15px rgb(244 247 250);
}

.faqs-accordion .acc-item{
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.faqs-accordion .acc-item:last-child {
    border-bottom: none;
}

.faqs-accordion .acc-item .acc-heding{
    color: #d2af6d;
    background: transparent;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 15px 0px;
    padding-right: 30px;
}

.faqs-accordion .acc-item .acc-heding.active {
    opacity: 0.7;
    color: rgb(55, 65, 81);
}

.faqs-accordion .acc-item .acc-heding.active:hover {
    opacity: 1;
    color: rgb(55, 65, 81);
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 48%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: 20px;
    border-radius: 50%;
    transition: 0.3s ease;
}

.faqs-accordion .acc-item .acc-heding.active span.down-icon {
    transform: translateY(-50%) rotate(180deg);
}

.faqs-accordion .acc-item .faqs-data{
    color: rgb(75, 85, 99);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 1.6;
}


.faqs-accordion .acc-item .faqs-data a {
    text-decoration: underline;
}

.faqs-accordion .acc-item .faqs-data a:hover {
    color: #d2af6d;
}

.faqs-accordion .acc-item .acc-data.active{
    opacity: 0;
    visibility: collapse;
    display: none;
}

.faqs-accordion .acc-item .acc-data{
    opacity: 1;
    visibility: visible;
    display: block;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

}
/* --- Faqs page css end --- */

/* --- custom-drop-bx start --- */
.custome-drop-bx{
    position: relative;
}

.custome-drop-bx .header-menu-item{
    cursor: pointer;
}

.custome-drop-bx:hover .header-menu-item{
    color: #d2af6d;
    background-color: rgba(244, 49, 134, 0.1);
}

.custome-drop-bx .custome-drop-item{
    position: absolute;
    top: calc(100% + 0px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid rgba(211, 211, 211, 0.616);
    background-color: white;
    padding: 5px 10px;
    width: 220px;
    border-radius: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.custome-drop-bx:hover .custome-drop-item{
    opacity: 1;
    visibility: visible;
}

.custome-drop-bx .custome-drop-item > *{
    display: block;
    padding: 8px 8px;
    border-radius: 5px;
    font-size: 15px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin: 5px 0;
}

.custome-drop-bx .custome-drop-item > *:hover{
    background-color: #d6d6d660;
}

.custome-drop-bx .custome-drop-item > *.active{
    color: #d2af6d;
    background-color: rgba(244, 49, 134, 0.1);
}
/* --- custom-drop-bx end --- */


/* --- Header Logo & Login buttons --- */
@media screen and (min-width:1024px) {
    .hlrsbs{
        width: 144px;
        flex: 0 0 144px;    
    }
}
/* --- Header Logo & Login buttons --- */


/* =============================
    order page success start
================================ */

.processing {
    padding: 30px 15px;
    background-color: #fdfdfd;
}

.processing .p-main-bx {
    text-align: center;
}

.processing .p-heading{
    color: #d2af6d;
    font-size: 28px;
    margin-top: 15px;
    margin-bottom: 25px;
    font-weight: 600;
    text-align: center;
}

.processing .pro-img-bx img {
    width: 160px;
    margin: 10px auto;
    margin-bottom: 25px;
}

.processing .p-order-id {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.processing .p-order-text{
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-top: 5px;
    color: rgb(75, 85, 99);
    font-weight: 500;
}

.processing .p-order-link {
    display: block;
    margin: 25px auto 10px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #d2af6d;
    outline: none !important;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    color: #d2af6d;
    font-size: 16px;
    font-weight: normal;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
}

.processing .p-order-link:hover {
    background-color: #d2af6d;
    color: white;
}


/* --- --- */
.PhoneInput{
    position: relative;
}

.PhoneInput input{
    margin-top: 4px;
    padding-left: 60px;
    background-color: rgb(255, 255, 255);
    border-color: rgb(229, 231, 235);
    border-radius: 16px;
}

.PhoneInput input:focus{
    border-color: rgb(125, 211, 252);
   box-shadow: 0 0 0px 3px rgba(2, 133, 199, 0.25);
}

.PhoneInput .PhoneInputCountry{
    position: absolute;
    left: 0;
    top: 4px;
    height: calc(100% - 4px);
    padding: 9px;
    margin: 0;
    pointer-events: none;
}

.PhoneInput .PhoneInputCountry::before{
    content: "";
    position: absolute;
    right: -2px;
    top: 9px;
    width: 1px;
    height: calc(100% - 20px);
    background-color: rgb(229, 231, 235);
}

.PhoneInput .PhoneInputCountry select{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

.PhoneInputCountrySelectArrow{
    position: relative;
    left: 3px;
    top: -2px;
    border-color: white;
    opacity: 1;
}


/* --- product-card-price --- */
.product-card-price > div{
    border: none !important;
    color: black !important;
    padding: 0 !important;
    font-size: 16px;
    margin-top: -5px;
}

.product-card-price > div > span{
    color: #525252 !important;
}

.video-main-bx{
    max-width: 768px;
    width: 100%;
    margin: auto;
}

.video-section-bx{
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding-top: 56%;
}

.video-section-bx iframe{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}


/* --- Main Slider --- */
.main-slider{
    max-width: 1920px;
    width: 100%;
    margin: auto;
    margin-bottom: -8px;
}

/* --- clients Slider Start --- */
.clientsslider-bx{
    max-width: 1920px;
    width: 100%;
    margin: auto;
    background-color: #fff6cc;
    padding: 30px 0;
}

.clientsslider-bx .cs-item{
    width: 170px !important;
    height: 100px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    margin: 0 40px;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /* opacity: 0.7; */
}

.clientsslider-bx .cs-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
}

.clientsslider-bx .cs-item:hover{
    opacity: 1;
}

@media screen and (max-width:992px) {
    .clientsslider-bx{
        padding: 25px 0;
    }

    .clientsslider-bx .cs-item{
        width: 140px !important;
        height: 100px;
        margin: 0 20px;
    }
}

@media screen and (max-width:768px) {
    .clientsslider-bx{
        padding: 20px 0;
    }

    .clientsslider-bx .cs-item{
        width: 120px !important;
        height: 80px;
        margin: 0 15px;
    }
}

@media screen and (max-width:576px) {
    .clientsslider-bx{
        padding: 10px 0;
    }
}
/* --- clients Slider End --- */


/* Chrome, Safari, Edge, Opera */
input.webkitSpinnone::-webkit-outer-spin-button,
input.webkitSpinnone::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
